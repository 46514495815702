



































































































import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { DaoToJoinViewModel } from '@/modules/community/home/viewmodels/dao-to-join-view-model'
@Observer
@Component({
  components: {
    'owner-section': () => import('@/modules/common/components/section/owner-section.vue'),
    'my-dao-section': () => import('@/modules/common/components/section/my-dao-section.vue'),
    'most-active-user-section': () => import('@/modules/common/components/section/most-active-user-section.vue'),
    'most-active-dao-section': () => import('@/modules/common/components/section/most-active-dao-section.vue'),
    'dao-to-join-list': () => import('@/modules/community/home/components/discovery/dao-to-join-list.vue'),
  },
})
export default class Review extends Vue {
  @Provide() vm = new DaoToJoinViewModel()
  createPostController = createPostController
}
