import { CommunityModel } from '@/models/community-model'
import { computed, observable } from 'mobx'

export class DaoStore {
  @observable dao?: CommunityModel
  constructor(dao: CommunityModel) {
    this.dao = dao
  }

  @computed get daoId() {
    return this.dao?._id
  }
  
  @computed get name() {
    return this.dao?.name
  }

  @computed get type() {
    return this.dao?.classification
  }

  @computed get description() {
    return this.dao?.description
  }

  @computed get totalFollowers() {
    return this.dao?.totalFollowers
  }

  @computed get totalPosts() {
    return this.dao?.totalPosts
  }
}
