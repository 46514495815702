import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { DaoStore } from '@/stores/dao-store'
import { computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class DaoToJoinViewModel {
  @observable daos: DaoStore[] = []
  @observable daoFetching = false
  @observable daoFilter = { _start: 0, _limit: 10 }
  @observable daoFilterState = { _start: 0, _limit: 10 }
  @observable loadingMore = false
  @observable total = 0

  constructor() {
    this.fetchDaos()
  }

  @asyncAction *fetchDaos() {
    try {
      this.daoFetching = true
      const res = yield apiService.daos.getDaosToJoin(this.daoFilter)
      if (res) {
        const daos = res.data.map((dao) => new DaoStore(dao))
        this.daos = daos
        this.total = res.total
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.daoFetching = false
    }
  }

  @asyncAction *loadMore() {
    try {
      this.loadingMore = true
      this.daoFilterState = { ...this.daoFilterState, _start: this.daoFilterState._start + this.daoFilterState._limit }
      const res = yield apiService.daos.getDaosToJoin(this.daoFilterState)
      const newDaos = res.data.map((dao) => new DaoStore(dao))
      this.daos = [...this.daos, ...newDaos]
    } catch (error) {
    } finally {
      this.loadingMore = false
    }
  }

  @computed get canLoadMore() {
    return this.daos.length < this.total
  }
}
